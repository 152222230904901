// Overrides must be outside @layer (or have !important on all rules).

.bs3 .dkform {
    .dvMultiselectInput, .dvRadioselectInput {
        margin-bottom: 16px;

        //:where(.checkbox, .radio) + :where(.checkbox, .radio) {
        :where(.checkbox, .radio) {
            padding-top: 0;
        }
        &.checkbox, &.radio {
            text-align: left;
        }
    }

    :is(.dvMultiselectInput, .dvRadioselectInput).direction-inline :is(.checkbox, .radio) {
        float: left;
        margin-right: 16px;
    }
    .direction-inline {
        .checkbox + .checkbox,
        .radio + .radio {
            padding-top: 7px !important;
        }
    }

}

.dkform.dkf {
    .fTable {
        border-collapse: separate;
        border-spacing: var(--dkform-gap, 10px);

        th {
            vertical-align: baseline;
            text-align: right;
            padding-top: 1ex;
            padding-right: 1ex;
        }
        th.hvRadioselectInput {padding-top: 0;}
        th.hvBooleanInput {padding-top:calc(1ex - 2px);}
    }
}

.dkform.plainforms {
    :is(.dvDateDropdown, .dvTimeInput) > label {
        grid-column: 1 / -1;
        text-align: left;
    }
}


.dkform.bootform3 {
    // move checkoxen to the right in the labels column
    .vBooleanInput, .vCheckboxInput {float: right;}

    // remove spinners from integer/hour/minute inputs.
    :where(.vIntegerInput, .vHourInput, .vMinuteInput) { -moz-appearance: textfield; }
    :where(.vIntegerInput, .vHourInput, .vMinuteInput)::-webkit-outer-spin-button,
    :where(.vIntegerInput, .vHourInput, .vMinuteInput)::-webkit-inner-spin-button { -webkit-appearance: none; }
}


.dkform.bootform5 {

    &[data-layout=standard] .row[field] {
        container: dkform-row / inline-size;

        &> label {
            text-align: right;
        }
    }
    //&[data-layout=standard] .row > label {
    //    text-align: right;
    //
    //
    //
    //    //@media (max-width: 576px) {
    //    //    text-align: left;
    //    //}
    //}

    .form-control-sm + .form-text {
        font-size: .8125rem;
        line-height: 1.1538;
    }

    .form-control-lg + .form-text {
        font-size: .9375rem;
        line-height: 1.333;
    }

    .form-check {
        // counteract the .row negative margin (the .row > * rule does this,
        // but BS's .form-check overwrites it with 1.5em)
        // (!important since we're overriding bootstrap and in a layer)
        padding-left: calc(var(--bs-gutter-x) * .5);

        .form-check-input {
            // Match BS specificity and undo margin-left: -1.5em; float: left;
            margin-left: 0;
            float: none;
        }

        .form-check-label {
            /* distance between checkbox and label */
            padding-left: .5ex;
        }
    }

    :is(.dvMultiselectInput, .dvRadioselectInput).direction-inline .form-check {
        float: left;
        margin-right: 1rem;
    }

    .dvMultiselectInput, .dvRadioselectInput {
        @media (min-width: 576px) {
            margin-top: .5em !important;
        }
        .form-check + .form-check {
            margin-top: -1px;
        }

        > .form-check {
            padding-left: 0 !important;

            .form-check-label {
                padding-left: 1ex;
            }
        }
    }
    .direction-inline {
        .form-check + .form-check {
            margin-top: 0 !important;
        }
        .form-check-label {padding-left: .5ex !important;}
    }

    [data-layout=stacked], [data-layout=floating] {
        .form-check {
            padding-left: 0;
        }

        .direction-inline::after {
            content: "";
            display: block;
            clear: both;
        }

        //[field]:has(.direction-inline) > label {
        //    display: block;
        //    clear: both;
        //}
    }
}

// less doesn't understand @container queries
@container dkform-row (391px <= width < 425px) {
    label::after {content: ""}
}

@container dkform-row (width < 409px) {
    .dkform.bootform5[data-layout=standard] .row[field] > label {
        text-align: left;
    }
    //label::after {content: "*"}
}



//noinspection CssInvalidAtRule
@layer dkforms {
    .dkform {
        --dkform-please-fix-errors: "(please fix errors)";
        --dkform-required-indicator: "*";
        --dkform-accesskey-font-weight: inherit;
        --dkform-accesskey-text-decoration: underline;
        --dkform-required-font-weight: bold;

        --dkform-validation-submit: #ccc;

        --dkform-validation-background-ok: var(--c-ok, rgba(144, 250, 144, 0.34));
        --dkform-validation-background-err: var(--c-warning, rgb(252, 212, 212));

        --dkform-validation-border-ok: var(--c-ok, rgba(73, 212, 73, 0.97));
        --dkform-validation-border-err: var(--c-warning, rgb(255, 84, 84));

        --dkform-validation-dot-ok: var(--c-ok, rgb(109 229 109));
        --dkform-validation-dot-err: var(--c-ok, hsl(14deg 93% 54% / 95%));
        --dkform-validation-dot-content: "\2022";

        //container-type: inline-size;
        //container-name: dkform;


        .large {
            font-size: 1.25rem;
        }

        .form-check {
            --dkform-required-indicator: "";
        }

        .accesskey {
            font-weight: var(--dkform-accesskey-font-weight);
            text-decoration: var(--dkform-accesskey-text-decoration);
        }

        .required {
            > label {
                font-weight: var(--dkform-required-font-weight);
                &::after {
                    content: var(--dkform-required-indicator);
                }
            }

        }

        .fForm[data-layout=standard] {
            label {
                text-align: right;

                @container dkform (max-width: 500px) {
                    text-align: left;
                }

                //@media (max-width: 576px) {
                //    text-align: left;
                //}
            }
        }

        .dMultiSelectInput {
            margin-top: .5em;
        }

        .dvDateDropdown {
            display: grid;
            align-items: center;
            grid-template-columns: [day] fit-content(5em) [sep1] 25px [month] fit-content(5em) [sep2] 25px [year] fit-content(5em);

            .vDateDropdown-separator {
                justify-self: center;
            }

            .help-block {
                grid-column: 1 / year-end;
                grid-row: 2;
            }
        }

        .vDatePickerInput {max-width: 20ch;}

        // remove arrows/spinners
        :where(.vHourInput, .vMinuteInput)::-webkit-outer-spin-button,
        :where(.vHourInput, .vMinuteInput)::-webkit-inner-spin-button {
            -webkit-appearance: none;  // chrome/safari/edge/opera
        }
        .vHourInput, .vMinuteInput {
            text-align: center;
            -moz-appearance: textfield !important;  // firefox
        }

        .dvTimeInput, .vDatetimeInput {
            display: grid;
            align-items: center;
            grid-template-columns: [hour] fit-content(4em) [sep] 20px [minute] fit-content(4em);
            text-align: center;
        }


        .dcompoundWidget label {
            text-align: left;
            // remove required indicator on sub-labels
            &::after {
                content: "" !important;
            }
        }

        // FIXME: check https://getbootstrap.com/docs/5.2/forms/validation/
        // Sudden validation changes is bad UX.
        // Transitions on user-events should be in the 100-200ms range.
        // Delayed transitions can take longer.
        // NOTE: you can't use :before or :after on input elements!
        // NOTE: we only display validation status when the input doesn't have focus.
        form[validate] {
            input {
                position: relative;
            }

            // only on forms that have validation turned on..
            // make the submit button grey until all fields are valid.
            &:has([data-valid=false]) [type=submit] {
                background-image: none !important;
                background-color: var(--dkform-validation-submit) !important;
                border-color: var(--dkform-validation-submit) !important;

                &::after {
                    content: var(--dkform-please-fix-errors);
                }
            }
        }

        .dkform-validation-background & form[validate] {
            --dkform-validation-transition-in: 200ms;
            --dkform-validation-transition-out: 100ms;
            --dkform-validation-transition-delay: 200ms;
            --dkform-validation-transition-fn: cubic-bezier(.9,.03,.69,.22);

            input {
                // transition when showing
                transition: background-color var(--dkform-validation-transition-out) linear 0s !important;
            }
            input:not(:focus) {
                // .. transition when hiding
                transition: background-color
                            var(--dkform-validation-transition-in)
                            var(--dkform-validation-transition-fn)
                            var(--dkform-validation-transition-delay)
                            !important;  // we need to override bs
            }
            // use :is to beat the above specificity.
            :is(.validated-true, [data-valid=true]):not(:focus) {
                background-color: var(--dkform-validation-background-ok) !important;
            }
            :is(.validated-false, [data-valid=false]):not(:focus) {
                background-color: var(--dkform-validation-background-err) !important;
            }
        }

        .dkform-validation-border & form[validate] {
            --dkform-validation-transition-in: 200ms;
            --dkform-validation-transition-out: 100ms;
            --dkform-validation-transition-delay: 200ms;
            --dkform-validation-transition-fn: cubic-bezier(.9,.03,.69,.22);

            input {
                // transition when showing
                transition: border-color var(--dkform-validation-transition-out) linear 0s !important;
            }
            input:not(:focus) {
                // .. transition when hiding
                transition: border-color
                            var(--dkform-validation-transition-in)
                            var(--dkform-validation-transition-fn)
                            var(--dkform-validation-transition-delay)
                            !important;  // we need to override bs
            }
            // use :is to beat the above specificity.
            :is(.validated-true, [data-valid=true]):not(:focus) {
                border-color: var(--dkform-validation-border-ok) !important;
            }
            :is(.validated-false, [data-valid=false]):not(:focus) {
                border-color: var(--dkform-validation-border-err) !important;
            }
        }

        .dkform-validation-dot & form[validate] {
            --dkform-validation-transition-in: 2s;
            --dkform-validation-transition-out: 100ms;

            [field] {
                position: relative;
                &::after {
                    content: var(--dkform-validation-dot-content);
                    display: block;
                    position: absolute;
                    right: 1.2rem;
                    top: 0.95rem;
                    color: transparent;
                    font-size: 3rem;
                    line-height: 0;
                    transition: color var(--dkform-validation-transition-out);
                }

                &:has(.validated-true):not(:has(:focus))::after {
                    color: var(--dkform-validation-dot-ok);
                    transition: color var(--dkform-validation-transition-in);
                }

                &:has(.validated-false):not(:has(:focus))::after {
                    color: var(--dkform-validation-dot-err);
                    transition: color var(--dkform-validation-transition-in);
                }
            }
        }

    }
}
